import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import "dayjs/locale/th";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Link from "@mui/material/Link";
import Popover from "@mui/material/Popover";
import FilledInput from "@mui/material/FilledInput";
import InputAdornment from "@mui/material/InputAdornment";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

import FingerprintIcon from "@mui/icons-material/Fingerprint";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PlaylistAddCircleRoundedIcon from "@mui/icons-material/PlaylistAddCircleRounded";
import GpsFixedRoundedIcon from "@mui/icons-material/GpsFixedRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

import DialogConfirmDelete from "../../shared/general/DialogConfirmDelete";
import ButtonBlue from "../../shared/general/ButtonBlue";
import AlertResponse from "../../shared/general/AlertResponse";

import StatusRequest from "../../shared/general/stausRequest";

import DialogRequest from "../../shared/dialogRequest";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
});

const StyledTable = styled(Table)({
  "& .MuiTableCell-root": {
    textAlign: "center",
  },
  "& .tableCellHead": {
    border: "none",
    backgroundColor: "#f3f6f8",
  },
  "& .tableCellHead:first-of-type": {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "rgb(255 255 255) 8px 0px 0px inset",
  },
  "& .cellAction": {
    width: 48,
    padding: "0px 0px 0px 4px",
  },
  "& .tableCellHead-date-time": {
    width: 120,
  },
  "& .tableCellHead-shift": {
    width: 100,
  },
  "& .tableCellHead:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    boxShadow: "rgb(255 255 255) -8px 0px 0px inset",
  },
  "& .tableCell": {
    borderBottom: "none",
    borderColor: "#919eab3d",
    padding: 12,
    "&.cellStartStop": {
      "&:hover": {
        cursor: "pointer",
      },
      "& .time": {
        fontWeight: "600",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& .MuiSvgIcon-root": {
          marginRight: 4,
        },
      },
      "& .location": {
        fontWeight: 600,
      },
    },
  },
  "& .tableCellWeekend": {
    color: "#959595",
    fontStyle: "oblique",
  },
  "& .cellStatusAction": {
    textAlign: "left",
    "& .wrap-status": {
      display: "flex",
      "& .item": {
        marginRight: 4,
      },
    },
  },
  "& .cellRequest": {},
  "& .cellStartStop": {
    minWidth: 80,
    "& .MuiButtonBase-root": {
      marginRight: 2,
    },
  },
});

const StyledChip = styled(Chip)(({ status }) => ({
  margin: 0,
  fontSize: 14,
  fontWeight: 600,
  height: 26,
  ...(status === "ขอรับรองเวลาทำงาน" && {
    backgroundColor: "#fffcd6",
    color: "#eebb00",
    "& .MuiSvgIcon-root": {
      color: "#eebb00",
    },
    "&.MuiButtonBase-root:hover": {
      backgroundColor: "#fff9ab",
    },
  }),
  ...(status === "ทำงานล่วงเวลา" && {
    backgroundColor: "#e9f0ff",
    color: "#1e4db6",
    "& .MuiSvgIcon-root": {
      color: "#1e4db6",
    },
    "&.MuiButtonBase-root:hover": {
      backgroundColor: "#d4e0f9",
    },
  }),
  ...(status === "ขาดงาน" && {
    backgroundColor: "#fdf3f5",
    color: "#fc4b6c",
  }),
  ...(status === "มาสาย" && {
    backgroundColor: "#ddf0ff",
    color: "#1997f5",
  }),
  ...(status === "วันหยุดประจำปี" && {
    backgroundColor: "#f7f5ff",
    color: "#7451ff",
  }),

  ...((status === "ลาป่วย" || status === "ลากิจ" || status === "ลาพักร้อน") && {
    backgroundColor: "#ecfff5",
    color: "#38ca7f",
  }),
}));

const StyledCard = styled(Card)({
  marginBottom: 32,
  padding: 16,
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  "& .MuiCardContent-root": {
    padding: 24,
  },
});

const StyledWrapLabel = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: 16,
  "& .MuiChip-root": {
    backgroundColor: "#f4f6f8",
    marginRight: 8,
    ["@media only screen and (max-width: 600px)"]: {
      marginRight: 0,
    },
    "& .MuiChip-label": {
      fontWeight: 600,
    },
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 8,
  marginBottom: 8,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledTableCellContent = styled(TableCell)({
  textAlign: "center",
  borderBottom: "none",
  padding: "8px 16px",
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  "&.status": {
    minWidth: 50,
    "& .MuiBox-root": {
      margin: "auto",
      "&:hover": {
        cursor: "pointer",
        transform: "scale(1.15)",
      },
    },
  },
  "& .fullname": {
    width: 250,
    "& .MuiAvatar-root": {
      marginRight: 16,
    },
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    "& .name": {
      textAlign: "left",
      fontWeight: 600,
      fontSize: 14,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: 300,
    },
  },
  "& .actual-plan": {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    maxWidth: 200,
    margin: "auto",
    padding: "0 20px",
  },
  "& .actual": {
    fontWeight: 600,
    fontSize: 24,
  },
  "& .plan": {
    fontWeight: 600,
    color: "#919eab",
  },
  "&:first-of-type": {
    paddingLeft: 16,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
});

const StyledTableRow = styled(TableRow)({
  "& .MuiTableRow-root:last-child.MuiTableCell-root": {
    border: "none !important",
  },
});

function Row(props) {
  const {
    key,
    row,
    handleClickOpenAddNewTimeline,
    openPopover,
    handlePopoverOpen,
    payrollSetting,
  } = props;
  const { t, i18n } = useTranslation();
  const { result: payrollSettingList } = useSelector(
    (state) => state.payrollSetting
  );
  const [open, setOpen] = React.useState(false);

  const whatLevel = (row) => {
    if (row.isDoubleApproval) {
      if (row.isManagerLV1Approve === 1) {
        return 2;
      } else {
        return 1;
      }
    } else {
      return null;
    }
  };

  const isRequestApprove = (row) => {
    if (row.idRequestTimeWithdraw) {
      return row.isApprove;
    } else {
      if (
        row.isManagerLV1Approve === null &&
        row.isManagerLV2Approve === null
      ) {
        return null;
      } else {
        if (row.isDoubleApproval) {
          if (row.isManagerLV1Approve === null) {
            return null;
          } else if (row.isManagerLV1Approve === 1) {
            if (row.isManagerLV2Approve === null) {
              return null;
            } else {
              return row.isManagerLV2Approve;
            }
          } else {
            return row.isManagerLV1Approve;
          }
        } else {
          if (row.approvalLevel === 1) {
            if (row.isManagerLV1Approve === null) {
              return null;
            } else {
              return row.isManagerLV1Approve;
            }
          } else {
            if (row.isManagerLV2Approve === null) {
              return null;
            } else {
              return row.isManagerLV2Approve;
            }
          }
        }
      }
    }
  };

  const renderOT = (row, payrollSetting) => {
    let textRow = [];
    if (payrollSetting) {
      if (row.xOT > 0) {
        textRow.push(
          <Typography style={{ fontWeight: 500 }} color="text.third" align="center" key="xOT">
            {`OT x ${payrollSetting.xOT}`} ={" "}
            {parseFloat(row.xOT / 60).toFixed(2)} ชม.
          </Typography>
        );
      }
      if (row.xWorkingDailyHoliday > 0) {
        textRow.push(
          <Typography style={{ fontWeight: 500 }} color="text.third" align="center" key="xWorkingDailyHoliday">
            {`OT x ${payrollSetting.xWorkingDailyHoliday}`} ={" "}
            {parseFloat(row.xWorkingDailyHoliday / 60).toFixed(2)} ชม.
          </Typography>
        );
      }
      if (row.xWorkingMonthlyHoliday > 0) {
        textRow.push(
          <Typography style={{ fontWeight: 500 }} color="text.third" align="center" key="xWorkingMonthlyHoliday">
            {`OT x ${payrollSetting.xWorkingMonthlyHoliday}`} ={" "}
            {parseFloat(row.xWorkingMonthlyHoliday / 60).toFixed(2)} ชม.
          </Typography>
        );
      }
      if (row.xOTHoliday > 0) {
        textRow.push(
          <Typography style={{ fontWeight: 500 }} color="text.third" align="center" key="xOTHoliday">
            {`OT x ${payrollSetting.xOTHoliday}`} ={" "}
            {parseFloat(row.xOTHoliday / 60).toFixed(2)} ชม.
          </Typography>
        );
      }
      if (textRow.length === 0) {
        return <Typography color="text.third">{""}</Typography>;
      } else {
        return textRow;
      }
    }
  };

  return (
    <React.Fragment>
      <TableRow key={key} style={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell className="tableCell">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowUpRoundedIcon />
            ) : (
              <KeyboardArrowDownRoundedIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell className={`tableCell`}>
          <Typography>
            {dayjs(row.date).format("D/MM/BBBB ")}
            <Typography component={"span"} color="text.secondary">
              {dayjs(row.date).format(" (dd)")}
            </Typography>
          </Typography>
        </TableCell>
        <TableCell className={`tableCell`}>
          <Typography>
            {row.pattern.idWorkingType === 1
              ? `${row.pattern.nameShiftGroup}  (${row.pattern.nameShift})`
              : `${row.pattern.nameShiftGroup}`}
          </Typography>
        </TableCell>
        <TableCell className={`tableCell`}>
          <Typography
            color="text.third"
            style={{
              fontStyle: "oblique",
              fontWeight: "normal",
            }}
          >
            {row.pattern.isWorkingDay === 1
              ? `${row.pattern.timeIn.slice(
                  0,
                  -3
                )} - ${row.pattern.timeOut.slice(0, -3)}`
              : row.pattern.nameShiftType}
          </Typography>
        </TableCell>
        {row.attendance.round1.checkIn === null &&
        row.attendance.round1.checkOut === null ? (
          <TableCell className={`tableCell`} colSpan={2} style={{ cursor: "default" }}>
            <Link
              component="button"
              variant="body2"
              style={{ cursor: "default" }}
              // onClick={() => {
              //   handleClickOpenAddNewTimeline(row);
              // }}
            >
              {t("TimeNotFound")}
            </Link>
          </TableCell>
        ) : (
          <Fragment>
            <TableCell className={`tableCell cellStartStop`}  style={{ cursor: "default" }}>
              <div
                style={{
                  minHeight: 45,
                  marginBottom: row.attendance.round2.checkIn ? 8 : 0,
                }}
              >
                {row.attendance.round1.checkIn ? (
                  <Fragment>
                    {row.attendance.round1.checkIn.idAttendanceType === 4 ? (
                      <div
                        style={{ minHeight:"fit-content" }}
                      >
                        <Typography
                          className="time"
                          aria-owns={
                            openPopover ? "mouse-over-popover" : undefined
                          }
                          aria-haspopup="true"
                          onClick={handlePopoverOpen(
                            row.attendance.round1.checkIn
                          )}
                        >
                          <GpsFixedRoundedIcon />
                          {row.attendance.round1.checkIn.attendanceTextTime}
                        </Typography>
                      </div>
                    ) : (
                      <div
                        style={{ minHeight:"fit-content" }}
                        // onClick={() => {
                        //   handleClickOpenAddNewTimeline(row);
                        // }}
                      >
                        <Typography className="time">
                          {row.attendance.round1.checkIn.idAttendanceType ===
                          1 ? (
                            <LocationOnIcon />
                          ) : (
                            <FingerprintIcon />
                          )}
                          {row.attendance.round1.checkIn.attendanceTextTime}
                        </Typography>
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          className="location"
                        >
                          {row.attendance.round1.checkIn.gpsLocationsName
                            ? row.attendance.round1.checkIn.gpsLocationsName
                            : ""}
                        </Typography>
                      </div>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    <Link
                      component="button"
                      variant="body2"
                      style={{ cursor: "default" }}
                      // onClick={() => {
                      //   handleClickOpenAddNewTimeline(row);
                      // }}
                    >
                      {t("TimeNotFound")}
                    </Link>
                  </Fragment>
                )}
              </div>
              {row.attendance.round2.checkIn && (
                <div style={{ minHeight: 45 }}>
                  <Fragment>
                    {row.attendance.round2.checkIn.idAttendanceType === 4 ? (
                      <div
                        style={{ minHeight:"fit-content" }}
                      >
                        <Typography
                          className="time"
                          aria-owns={
                            openPopover ? "mouse-over-popover" : undefined
                          }
                          aria-haspopup="true"
                          onClick={handlePopoverOpen(
                            row.attendance.round2.checkIn
                          )}
                        >
                          <GpsFixedRoundedIcon />
                          {row.attendance.round2.checkIn.attendanceTextTime}
                        </Typography>
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          className="location"
                        >
                          {row.attendance.round1.checkIn.gpsAddress
                            ? row.attendance.round1.checkIn.gpsAddress.replace(
                                ", Thailand",
                                ""
                              )
                            : ""}
                        </Typography>
                      </div>
                    ) : (
                      <div
                        style={{ minHeight:"fit-content" }}
                        // onClick={() => {
                        //   handleClickOpenAddNewTimeline(row);
                        // }}
                      >
                        <Typography className="time">
                          {row.attendance.round2.checkIn.idAttendanceType ===
                          1 ? (
                            <LocationOnIcon />
                          ) : (
                            <FingerprintIcon />
                          )}
                          {row.attendance.round2.checkIn.attendanceTextTime}
                        </Typography>
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          className="location"
                        >
                          {row.attendance.round2.checkIn.gpsLocationsName
                            ? row.attendance.round2.checkIn.gpsLocationsName
                            : ""}
                        </Typography>
                      </div>
                    )}
                  </Fragment>
                </div>
              )}
            </TableCell>
            <TableCell className={`tableCell cellStartStop`}  style={{ cursor: "default" }}>
              <div
                style={{
                  minHeight: 45,
                  marginBottom: row.attendance.round2.checkIn ? 8 : 0,
                }}
              >
                {row.attendance.round1.checkOut ? (
                  <Fragment>
                    {row.attendance.round1.checkOut.idAttendanceType === 4 ? (
                      <div
                        style={{ minHeight:"fit-content" }}
                      >
                        <Typography
                          className="time"
                          aria-owns={
                            openPopover ? "mouse-over-popover" : undefined
                          }
                          aria-haspopup="true"
                          onClick={handlePopoverOpen(
                            row.attendance.round1.checkOut
                          )}
                        >
                          <GpsFixedRoundedIcon />
                          {row.attendance.round1.checkOut.attendanceTextTime}
                        </Typography>
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          className="location"
                        >
                          {row.attendance.round1.checkOut.gpsAddress
                            ? row.attendance.round1.checkOut.gpsAddress.replace(
                                ", Thailand",
                                ""
                              )
                            : ""}
                        </Typography>
                      </div>
                    ) : (
                      <div
                        style={{ minHeight:"fit-content" }}
                        // onClick={() => {
                        //   handleClickOpenAddNewTimeline(row);
                        // }}
                      >
                        <Typography className="time">
                          {row.attendance.round1.checkOut.idAttendanceType ===
                          1 ? (
                            <LocationOnIcon />
                          ) : (
                            <FingerprintIcon />
                          )}
                          {row.attendance.round1.checkOut.attendanceTextTime}
                        </Typography>

                        <Typography
                          variant="caption"
                          color="text.secondary"
                          className="location"
                        >
                          {row.attendance.round1.checkOut.gpsLocationsName
                            ? row.attendance.round1.checkOut.gpsLocationsName
                            : ""}
                        </Typography>
                      </div>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    <Link
                      component="button"
                      variant="body2"
                      style={{ cursor: "default" }}
                      // onClick={() => {
                      //   handleClickOpenAddNewTimeline(row);
                      // }}
                    >
                      {t("TimeNotFound")}
                    </Link>
                  </Fragment>
                )}
              </div>
              {row.attendance.round2.checkIn && (
                <div style={{ minHeight: 45 }}>
                  {row.attendance.round2.checkOut ? (
                    <Fragment>
                      {row.attendance.round2.checkOut.idAttendanceType === 4 ? (
                        <div
                          style={{ minHeight:"fit-content" }}
                        >
                          <Typography
                            className="time"
                            aria-owns={
                              openPopover ? "mouse-over-popover" : undefined
                            }
                            aria-haspopup="true"
                            onClick={handlePopoverOpen(
                              row.attendance.round2.checkOut
                            )}
                          >
                            <GpsFixedRoundedIcon />
                            {row.attendance.round2.checkOut.attendanceTextTime}
                          </Typography>
                        </div>
                      ) : (
                        <div
                          style={{ minHeight:"fit-content" }}
                          // onClick={() => {
                          //   handleClickOpenAddNewTimeline(row);
                          // }}
                        >
                          <Typography className="time">
                            {row.attendance.round2.checkOut.idAttendanceType ===
                            1 ? (
                              <LocationOnIcon />
                            ) : (
                              <FingerprintIcon />
                            )}
                            {row.attendance.round2.checkOut.attendanceTextTime}
                          </Typography>

                          <Typography
                            variant="caption"
                            color="text.secondary"
                            className="location"
                          >
                            {row.attendance.round2.checkOut.gpsLocationsName
                              ? row.attendance.round2.checkOut.gpsLocationsName
                              : ""}
                          </Typography>
                        </div>
                      )}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Link
                        component="button"
                        variant="body2"
                        style={{ cursor: "default" }}
                        // onClick={() => {
                        //   handleClickOpenAddNewTimeline(row);
                        // }}
                      >
                        {t("TimeNotFound")}
                      </Link>
                    </Fragment>
                  )}
                </div>
              )}
            </TableCell>
          </Fragment>
        )}
        <TableCell align="center" className={`tableCell cellStatusAction`}>
          <div className="wrap-status">
            {(row.isLate && row.isEarlyOut && !row.absent) && 
              <div className="item">
                <StyledChip label={`${t("LateAndEarly")}`} status={"ขาดงาน"} />
              </div>
            }
            {(row.isLate && !row.isEarlyOut && !row.absent) && 
              <div className="item">
                <StyledChip label={`${t("Late")}`} status={"ขาดงาน"} />
              </div>
            }
            {(!row.isLate && row.isEarlyOut && !row.absent) && 
              <div className="item">
                <StyledChip label={`${t("EarlyOut")}`} status={"ขาดงาน"} />
              </div>
            }
            {row.absent && (
              <div className="item">
                <StyledChip label={"ขาดงาน"} status={"ขาดงาน"} />
              </div>
            )}
            {row.requestTime && row.requestTime.length > 0 && (
              <div className="item">
                <PlaylistAddCircleRoundedIcon style={{ color: "#eebb00" }} />
              </div>
            )}
            {row.ot && row.ot.length > 0 && (
              <div className="item">
                <PlaylistAddCircleRoundedIcon style={{ color: "#1e4db6" }} />
              </div>
            )}
            {row.leave && row.leave.length > 0 && (
              <div className="item">
                <PlaylistAddCircleRoundedIcon style={{ color: "#ff6d00" }} />
              </div>
            )}
            {row.holiday && (
              <div className="item">
                <StyledChip
                  label={"วันหยุดประจำปี"}
                  status={"วันหยุดประจำปี"}
                />
              </div>
            )}
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box style={{ margin: 8 }}>
              <Typography align="left" variant="h6" gutterBottom>
                รายการ
              </Typography>
              {row.requestTime &&
                row.requestTime.map((request) => (
                  <StyledTableRow>
                    <StyledTableCellContent align="center" className="status">
                      <StatusRequest
                        boxSize={40}
                        fontSize={22}
                        borderRadius={8}
                        step={whatLevel(request)}
                        status={isRequestApprove(request)}
                        active={request.isActive}
                      />
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Chip
                        variant="outlined"
                        icon={
                          <PlaylistAddCircleRoundedIcon
                            style={{ color: "#eebb00" }}
                          />
                        }
                        label="คำขอรับรองเวลาทำงาน"
                      />
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center" variant="h6">
                        {dayjs(request.startText, "DD/MM/YYYY HH:mm").format(
                          "HH:mm"
                        )}
                      </Typography>
                      <Typography
                        align="center"
                        variant="body2"
                        color="text.third"
                      >
                        {dayjs(request.startText, "DD/MM/YYYY HH:mm").format(
                          "DD/MM/YYYY"
                        )}
                      </Typography>
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center" variant="h6">
                        {dayjs(request.endText, "DD/MM/YYYY HH:mm").format(
                          "HH:mm"
                        )}
                      </Typography>
                      <Typography
                        align="center"
                        variant="body2"
                        color="text.third"
                      >
                        {dayjs(request.endText, "DD/MM/YYYY HH:mm").format(
                          "DD/MM/YYYY"
                        )}
                      </Typography>
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center">
                        {request.reasonName ? request.reasonName : ""}
                      </Typography>
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center">
                        {request.otherReason ? request.otherReason : ""}
                      </Typography>
                    </StyledTableCellContent>
                  </StyledTableRow>
                ))}
              {row.ot &&
                row.ot.map((request) => (
                  <TableRow>
                    <StyledTableCellContent align="center" className="status">
                      <StatusRequest
                        boxSize={40}
                        fontSize={22}
                        borderRadius={8}
                        step={whatLevel(request)}
                        status={isRequestApprove(request)}
                        active={request.isActive}
                      />
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Chip
                        variant="outlined"
                        icon={
                          <PlaylistAddCircleRoundedIcon
                            style={{ color: "#1e4db6" }}
                          />
                        }
                        label="คำขอทำงานล่วงเวลา"
                      />
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center" variant="h6">
                        {dayjs(request.startText, "DD/MM/YYYY HH:mm").format(
                          "HH:mm"
                        )}
                      </Typography>
                      <Typography
                        align="center"
                        variant="body2"
                        color="text.third"
                      >
                        {dayjs(request.startText, "DD/MM/YYYY HH:mm").format(
                          "DD/MM/YYYY"
                        )}
                      </Typography>
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center" variant="h6">
                        {dayjs(request.endText, "DD/MM/YYYY HH:mm").format(
                          "HH:mm"
                        )}
                      </Typography>
                      <Typography
                        align="center"
                        variant="body2"
                        color="text.third"
                      >
                        {dayjs(request.endText, "DD/MM/YYYY HH:mm").format(
                          "DD/MM/YYYY"
                        )}
                      </Typography>
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center">
                        {(request.idRequestType === 2 ||
                          request.idRequestType === 3) &&
                          payrollSettingList &&
                          renderOT(request, row.payrollSetting)}
                      </Typography>
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center">
                        {request.reasonName ? request.reasonName : ""}
                      </Typography>
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center">
                        {request.otherReason}
                      </Typography>
                    </StyledTableCellContent>
                  </TableRow>
                ))}
              {row.leave &&
                row.leave.map((request) => (
                  <TableRow>
                    <StyledTableCellContent align="center" className="status">
                      <StatusRequest
                        boxSize={40}
                        fontSize={22}
                        borderRadius={8}
                        status={
                          request.isApprove === 1
                            ? 1
                            : request.isApprove === 0
                            ? 0
                            : null
                        }
                        active={request.isActive}
                      />
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Chip
                        variant="outlined"
                        icon={
                          <PlaylistAddCircleRoundedIcon
                            style={{ color: "#ff6d00" }}
                          />
                        }
                        label="คำขอลางาน"
                      />
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      {request.isFullDay === 1 ? (
                        <Typography
                          align="center"
                          variant="h6"
                          style={{ fontSize: 16 }}
                        >
                          {dayjs(request.startText, "DD/MM/YYYY HH:mm").format(
                            "DD/MM/YYYY"
                          )}
                        </Typography>
                      ) : (
                        <Fragment>
                          <Typography align="center" variant="h6">
                            {dayjs(
                              request.startText,
                              "DD/MM/YYYY HH:mm"
                            ).format("HH:mm")}
                          </Typography>
                          <Typography
                            align="center"
                            variant="body2"
                            color="text.third"
                          >
                            {dayjs(
                              request.startText,
                              "DD/MM/YYYY HH:mm"
                            ).format("DD/MM/YYYY")}
                          </Typography>
                        </Fragment>
                      )}
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      {request.isFullDay === 1 ? (
                        <Typography
                          align="center"
                          variant="h6"
                          style={{ fontSize: 16 }}
                        >
                          {dayjs(request.endText, "DD/MM/YYYY HH:mm").format(
                            "DD/MM/YYYY"
                          )}
                        </Typography>
                      ) : (
                        <Fragment>
                          <Typography align="center" variant="h6">
                            {dayjs(request.endText, "DD/MM/YYYY HH:mm").format(
                              "HH:mm"
                            )}
                          </Typography>
                          <Typography
                            align="center"
                            variant="body2"
                            color="text.third"
                          >
                            {dayjs(request.endText, "DD/MM/YYYY HH:mm").format(
                              "DD/MM/YYYY"
                            )}
                          </Typography>
                        </Fragment>
                      )}
                    </StyledTableCellContent>
                    <StyledTableCellContent colSpan={3}>
                      <Typography align="center" style={{ fontWeight: 600 }}>
                        {request.name}
                      </Typography>
                      <Typography align="center" color="text.secondary">
                        {request.description}
                      </Typography>
                    </StyledTableCellContent>
                  </TableRow>
                ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

function Individual(props) {
  const { result: attendanceList } = useSelector((state) => state.attendance);
  const { isFetching: attendanceIsFetching } = useSelector(
    (state) => state.attendance
  );
  const { isError: attendanceIsError } = useSelector(
    (state) => state.attendance
  );
  const [openDialogRequest, setOpenDialogRequest] = React.useState(false);
  const [openDialogConfirmDelete, setOpenDialogConfirmDelete] =
    React.useState(false);
  const [valueDialog, setValueDialog] = React.useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [anchorElPopover, setAnchorElPopover] = React.useState(null);
  const [overData, setOverData] = React.useState(null);
  const openPopover = Boolean(anchorElPopover);
  const [attendanceListState, setAttendanceListState] = useState([]);

  useEffect(() => {
    if (attendanceIsError) {
      setAlertType("เกิดข้อผิดพลาด ลองอีกครั้ง");
      handleOpenAlert();
    }
  }, [attendanceIsError]);

  useEffect(() => {
    if (attendanceList && attendanceList.length > 0) {
      let temp = attendanceList.map((v, i) => {
        let previousLeave = [];
        let previousPattern = null;
        let nextLeave = [];
        let nextPattern = null;
        if(i !== 0){
          previousPattern = attendanceList[i-1].pattern;
          previousLeave = attendanceList[i-1].leave;
        }
        if(i !== attendanceList.length - 1){
          nextLeave = attendanceList[i+1].leave;
          nextPattern = attendanceList[i+1].pattern;
        }
        
        return {
          ...v,
          previousPattern: previousPattern,
          previousLeave: previousLeave,
          nextLeave: nextLeave,
          nextPattern: nextPattern
        }
      });
      setAttendanceListState(temp);
    }
  }, [attendanceList]);

  const handleClickOpenAddNewTimeline = (row) => {
    handlePopoverClose();
    setOpenDialogRequest(true);
    setValueDialog(row);
  };

  const handleCloseDialog = () => {
    setValueDialog(null);
    setOpenDialogRequest(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleOpenDialogConfirmDelete = () => {
    setOpenDialogConfirmDelete(true);
  };

  const handleCloseDialogConfirmDelete = () => {
    setOpenDialogConfirmDelete(false);
  };

  const handlePopoverOpen = (row) => (event) => {
    if (row) {
      setOverData(row);
      setAnchorElPopover(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    console.log("handlePopoverClose");
    setAnchorElPopover(null);
    setOverData(null);
  };

  return (
    <div style={{ paddingBottom: 16 }}>
      <div style={{ marginTop: 16 }}>
        {attendanceIsFetching ? (
          <Typography align="center">กำลังโหลดข้อมูล...</Typography>
        ) : (
          <Fragment>
            {attendanceList ? (
              <StyledCard>
                <StyledWrapLabel>
                  <div>
                    <Chip
                      icon={
                        <PlaylistAddCircleRoundedIcon
                          style={{ color: "#eebb00" }}
                        />
                      }
                      label="คำขอรับรองเวลาทำงาน"
                    />
                  </div>
                  <div>
                    <Chip
                      icon={
                        <PlaylistAddCircleRoundedIcon
                          style={{ color: "#1e4db6" }}
                        />
                      }
                      label="คำขอทำงานล่วงเวลา"
                    />
                  </div>
                  <div>
                    <Chip
                      icon={
                        <PlaylistAddCircleRoundedIcon
                          style={{ color: "#ff6d00" }}
                        />
                      }
                      label="คำขอลางาน"
                    />
                  </div>
                </StyledWrapLabel>
                <TableContainer className="tableContainer">
                  <StyledTable aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell className={`tableCellHead`} />
                        <TableCell
                          className={`tableCellHead tableCellHead-date-time`}
                        >
                          วันที่
                        </TableCell>
                        <TableCell
                          className={`tableCellHead tableCellHead-shift`}
                        >
                          ตารางกะ
                        </TableCell>
                        <TableCell
                          className={`tableCellHead tableCellHead-shift`}
                        >
                          เวลาทำงาน
                        </TableCell>
                        <TableCell className={`tableCellHead cellStartStop`}>
                          เวลาเข้า
                        </TableCell>
                        <TableCell className={`tableCellHead cellStartStop`}>
                          เวลาออก
                        </TableCell>
                        <TableCell
                          className={`tableCellHead cellStatusAction`}
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {attendanceListState &&
                      attendanceListState.length > 0 &&
                        attendanceListState.map((row, index) => {
                          if (
                            dayjs(row.date).isBetween(
                              props.search.start,
                              props.search.end,
                              "day",
                              "[]"
                            )
                          ) {
                            return (
                              <Row
                                key={index}
                                row={row}
                                handleClickOpenAddNewTimeline={
                                  handleClickOpenAddNewTimeline
                                }
                                openPopover={openPopover}
                                handlePopoverOpen={handlePopoverOpen}
                              />
                            );
                          }
                        })}
                    </TableBody>
                  </StyledTable>
                </TableContainer>
              </StyledCard>
            ) : (
              <div>
                <Typography align="center">ไม่มีข้อมูล</Typography>
              </div>
            )}
          </Fragment>
        )}
      </div>

      {overData && (
        <Popover
          id="mouse-over-popover"
          open={openPopover}
          anchorEl={anchorElPopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onClose={handlePopoverClose}
        >
          <Typography style={{ padding: 16 }}>
            {overData.gpsAddress.replace(", Thailand", "")}
            {/* <Typography component="p">
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  handleClickOpenAddNewTimeline(overData);
                }}
              >
                เพิ่มรายการ
              </Link>
            </Typography> */}
          </Typography>
        </Popover>
      )}

      {valueDialog && (
        <DialogRequest
          values={valueDialog}
          open={openDialogRequest}
          handleCloseDialog={handleCloseDialog}
          handleChangeAlertType={handleChangeAlertType}
          handleOpenAlert={handleOpenAlert}
          handleOpenDialogConfirmDelete={handleOpenDialogConfirmDelete}
          attendanceListState={attendanceListState}
          searchDate={{
            searchDate: props.search,
            idCompany: props.employee.idCompany,
            idEmp: props.employee.idEmp,
          }}
          employeeProfile={props.employee}
          isUserTimelinePage={false}
          isFillIn={true}
        />
      )}

      {openDialogConfirmDelete && (
        <DialogConfirmDelete
          open={openDialogConfirmDelete}
          handleClose={handleCloseDialogConfirmDelete}
        />
      )}

      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </div>
  );
}

export default Individual;
