import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";

import EditIcon from "@mui/icons-material/Edit";

import StatusRequest from "../../shared/general/stausRequest";
import DialogRequest from "./DialogRequest";
import DialogConfirmDelete from "../../shared/general/DialogConfirmDelete";
import RequestDialog from "./components/RequestDialog";

import {
  getAllRequestTimeBy,
  withdrawRequestTime,
} from "../../../../actions/requestTime";

const StyledAvatar = styled(Avatar)({
  width: 35,
  height: 35,
});

const StyledChip = styled(Chip)({
  height: 20,
  backgroundColor: "#ff3034",
  marginRight: 8,
  "& .MuiChip-label": {
    paddingLeft: 8,
    paddingRight: 8,
    color: "#FFFFFF",
    fontWeight: 600,
  },
});

const StyledBadge = styled(Badge)({
  "& .MuiBadge-anchorOriginTopRightRectangular": {
    backgroundColor: "#ff3034",
  },
});

const StyledCellName = styled("div")({
  display: "flex",
  alignItems: "center",
  "& .MuiAvatar-root": {
    marginRight: 8,
  },
  "& .MuiTypography-body1": {
    width: 200,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "& .MuiTypography-body2": {
    color: "#999999",
    fontStyle: "oblique",
  },
});

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  padding: "8px 14px",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  "& .MuiTypography-root": {
    color: "#637381",
    backgroundColor: "#f4f6f8",
    "&.weekend": {
      fontStyle: "oblique",
    },
    "&.workday": {
      fontWeight: 600,
    },
  },
  "&:first-of-type": {
    paddingLeft: 16,
    paddingRight: 0,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  "&:last-of-type": {
    paddingRight: 8,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
  },
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: 8,
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
  },
  "& .avatar": {
    minWidth: 240,
    "& .MuiAvatar-root": {
      marginRight: 8,
    },
    display: "flex",
    alignItems: "center",
  },
  "& .position": {
    color: theme.palette.text.secondary,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 300,
  },
  "& .secondaryAction": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .location": {
      fontWeight: 600,
    },
  },
  "& .reason": {
    "& .main": {
      fontWeight: 500,
    },
    "& .other": {
      fontWeight: 500,
    },
  },
  "&:first-of-type": {
    paddingLeft: 16,
    paddingRight: 0,
  },
  "&:last-of-type": {
    paddingRight: 8,
  },
  "& .request-withdraw": {
    display: "flex",
    alignItems: "center",
  },
}));

export default function TableApproveList(props) {
  const {
    dataTable,
    checkbox,
    approvalSelected,
    setApprovalSelected,
    searchName,
    setNumberOfList,
    isRequestTimeOT,
    handleOpenAlert,
    handleChangeAlertType,
    searchDate,
  } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  dayjs.extend(customParseFormat);
  const { user: authUser } = useSelector((state) => state.auth);
  const { result: payrollSettingList } = useSelector(
    (state) => state.payrollSetting
  );
  const [openDialogRequest, setOpenDialogRequest] = useState({
    open: false,
    request: null,
  });
  const [openDialog, setOpenDialog] = useState({
    isOpen: false,
    request: [],
  });
  const [filterData, setFilterData] = useState(dataTable);
  const [openDialogConfirmDelete, setOpenDialogConfirmDelete] =
    React.useState(false);

  const headCells = [
    {
      id: "name",
      label: `${t("FullName")}`,
      minWidth: 200,
    },
    {
      id: "leaveTypeName",
      label: `${t("Type")}`,
    },
    {
      id: "description",
      label: `${t("Reason")}`,
    },
    {
      id: "start-end",
      label: `${t("Start")}-${t("End")}`,
      minWidth: 220,
    },
    {
      id: "edit",
      label: "",
      minWidth: 50,
    },
  ];

  useEffect(() => {
    let temp = dataTable.filter((n) => {
      if (searchName && searchName !== "") {
        if (
          n.firstname.includes(searchName) ||
          n.lastname.includes(searchName)
        ) {
          return true;
        }
      } else {
        return true;
      }
      return false;
    });
    setFilterData(temp);
    setNumberOfList(temp.length);
  }, [searchName, dataTable]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setApprovalSelected(filterData);
      return;
    }

    setApprovalSelected([]);
  };

  const handleClick = (_, row) => {
    const selectedIndex = approvalSelected.indexOf(row);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(approvalSelected, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(approvalSelected.slice(1));
    } else if (selectedIndex === approvalSelected.length - 1) {
      newSelected = newSelected.concat(approvalSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        approvalSelected.slice(0, selectedIndex),
        approvalSelected.slice(selectedIndex + 1)
      );
    }

    setApprovalSelected(newSelected);
  };

  const isSelected = (row) => approvalSelected.indexOf(row) !== -1;

  const renderOT = (row) => {
    const findPayroll = payrollSettingList.find(
      (x) => x.idCompany === row.idCompany && x.idVendor === row.idVendor
    );

    const payrollSetting = findPayroll ? findPayroll : payrollSettingList[0];

    let textRow = [];
    if (payrollSetting) {
      if (row.xOT > 0) {
        textRow.push(
          <Typography style={{ fontWeight: 500 }} color="text.third" key="xOT">
            {`OT x ${payrollSetting.xOT}`} ={" "}
            {parseFloat(row.xOT / 60).toFixed(2)} ชม.
          </Typography>
        );
      }
      if (row.xWorkingDailyHoliday > 0) {
        textRow.push(
          <Typography
            style={{ fontWeight: 500 }}
            color="text.third"
            key="xWorkingDailyHoliday"
          >
            {`OT x ${payrollSetting.xWorkingDailyHoliday}`} ={" "}
            {parseFloat(row.xWorkingDailyHoliday / 60).toFixed(2)} ชม.
          </Typography>
        );
      }
      if (row.xWorkingMonthlyHoliday > 0) {
        textRow.push(
          <Typography
            style={{ fontWeight: 500 }}
            color="text.third"
            key="xWorkingMonthlyHoliday"
          >
            {`OT x ${payrollSetting.xWorkingMonthlyHoliday}`} ={" "}
            {parseFloat(row.xWorkingMonthlyHoliday / 60).toFixed(2)} ชม.
          </Typography>
        );
      }
      if (row.xOTHoliday > 0) {
        textRow.push(
          <Typography
            style={{ fontWeight: 500 }}
            color="text.third"
            key="xOTHoliday"
          >
            {`OT x ${payrollSetting.xOTHoliday}`} ={" "}
            {parseFloat(row.xOTHoliday / 60).toFixed(2)} ชม.
          </Typography>
        );
      }
      if (row.xWorkingDaily > 0) {
        textRow.push(
          <Typography
            style={{ fontWeight: 500 }}
            color="text.third"
            key="xWorkingDaily"
          >
            {`OT x 1`} ={" "}
            {parseFloat(row.xWorkingDaily / 60).toFixed(2)} ชม.
          </Typography>
        );
      }
      if (textRow.length === 0) {
        return <Typography color="text.third">{""}</Typography>;
      } else {
        return textRow;
      }
    }
  };

  const whatLevel = (row) => {
    if (row.isDoubleApproval) {
      if (row.isManagerLV1Approve === 1) {
        return 2;
      } else {
        return 1;
      }
    } else {
      return null;
    }
  };

  const isRequestApprove = (row) => {
    if (row.idRequestTimeWithdraw) {
      return row.isApprove;
    } else {
      if (
        row.isManagerLV1Approve === null &&
        row.isManagerLV2Approve === null
      ) {
        return null;
      } else {
        if (row.isDoubleApproval) {
          if (row.isManagerLV1Approve === null) {
            return null;
          } else if (row.isManagerLV1Approve === 1) {
            if (row.isManagerLV2Approve === null) {
              return null;
            } else {
              return row.isManagerLV2Approve;
            }
          } else {
            return row.isManagerLV1Approve;
          }
        } else {
          if (row.approvalLevel === 1) {
            if (row.isManagerLV1Approve === null) {
              return null;
            } else {
              return row.isManagerLV1Approve;
            }
          } else {
            if (row.isManagerLV2Approve === null) {
              return null;
            } else {
              return row.isManagerLV2Approve;
            }
          }
        }
      }
    }
  };

  const handleOpenDialogConfirmDelete = () => {
    setOpenDialogConfirmDelete(true);
  };

  const handleCloseDialogConfirmDelete = () => {
    setOpenDialogConfirmDelete(false);
  };

  const handleDelete = async () => {
    const formData = {
      idRequestTime: openDialogRequest.request.idRequestTime,
      updateBy: authUser.id,
      updateDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    };

    const result = await dispatch(withdrawRequestTime(formData));
    if (result && result.status === 200) {
      setOpenDialogRequest({ open: false, request: null });
      handleCloseDialogConfirmDelete();
      handleChangeAlertType("success");
      handleOpenAlert();
      if (searchDate.start && searchDate.end) {
        dispatch(getAllRequestTimeBy("manager", searchDate));
      } else {
        dispatch(getAllRequestTimeBy("manager"));
      }
    } else {
      handleCloseDialogConfirmDelete();
      handleChangeAlertType("error");
      handleOpenAlert();
    }
  };

  return (
    <Box style={{ width: "100%" }}>
      <TableContainer style={{ width: "100%", height: 450 }}>
        <Table aria-label="table" stickyHeader>
          <TableHead>
            <TableRow>
              {checkbox && dataTable ? (
                <StyledCellHeader>
                  <Checkbox
                    style={{ padding: 0 }}
                    color="primary"
                    indeterminate={
                      approvalSelected.length > 0 &&
                      approvalSelected.length < filterData.length
                    }
                    checked={
                      approvalSelected.length > 0 &&
                      approvalSelected.length === filterData.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all desserts",
                    }}
                  />
                </StyledCellHeader>
              ) : (
                <StyledCellHeader>{t("Status")}</StyledCellHeader>
              )}

              {headCells.map((column, index) => {
                if (index === headCells.length - 1) {
                  if (isRequestTimeOT) {
                    return (
                      <StyledCellHeader
                        key={column.id}
                        style={{
                          minWidth: column.minWidth ? column.minWidth : 150,
                        }}
                      >
                        <Typography variant="body2" align="center">
                          {column.label}
                        </Typography>
                      </StyledCellHeader>
                    );
                  }
                } else {
                  return (
                    <StyledCellHeader
                      key={column.id}
                      style={{
                        minWidth: column.minWidth ? column.minWidth : 150,
                      }}
                    >
                      <Typography variant="body2" align="center">
                        {column.label}
                      </Typography>
                    </StyledCellHeader>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataTable && dataTable.length > 0 && filterData.length > 0 ? (
              filterData.map((row, index) => {
                const isItemSelected = isSelected(row);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <StyledRowContent
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={`${index}`}
                    aria-checked={isItemSelected}
                    selected={isItemSelected}
                  >
                    {checkbox ? (
                      <StyledCellContent padding="checkbox">
                        <Checkbox
                          style={{ padding: 0 }}
                          onClick={(event) => handleClick(event, row)}
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </StyledCellContent>
                    ) : (
                      <Fragment>
                        {row.idLeave ? (
                          <StyledCellContent>
                            <StatusRequest
                              boxSize={40}
                              fontSize={22}
                              borderRadius={8}
                              status={
                                row.isApprove === 1
                                  ? 1
                                  : row.isApprove === 0
                                  ? 0
                                  : null
                              }
                              active={row.isActive}
                            />
                          </StyledCellContent>
                        ) : (
                          <StyledCellContent>
                            <StatusRequest
                              boxSize={40}
                              fontSize={22}
                              borderRadius={8}
                              step={whatLevel(row)}
                              status={isRequestApprove(row)}
                              active={row.isActive}
                            />
                          </StyledCellContent>
                        )}
                      </Fragment>
                    )}

                    <StyledCellContent className={`fullname`}>
                      <StyledCellName>
                        <StyledAvatar
                          alt={"username"}
                          src={`${process.env.REACT_APP_API_URL}image/profile/1.jpg`}
                        />
                        <div>
                          <Typography
                            style={{
                              fontSize: "18px",
                              fontWeight: 600,
                            }}
                          >
                            {`${row.firstname} ${row.lastname}`}
                          </Typography>
                          <Typography variant="body2">
                            {row.positionsName}
                          </Typography>
                        </div>
                      </StyledCellName>
                    </StyledCellContent>
                    <StyledCellContent className={`cellName`}>
                      <div className="request-withdraw">
                        {(row.idRequestTimeWithdraw ||
                          row.idLeaveEmployeesWithdraw) && (
                          <StyledChip label="ถอน" />
                        )}
                        {row.isWithdraw ? (
                          <StyledBadge badgeContent=" " variant="dot">
                            <Typography color="text.primary">
                              {row.name}
                            </Typography>
                          </StyledBadge>
                        ) : (
                          <Typography color="text.primary">
                            {row.name}
                          </Typography>
                        )}
                      </div>
                      {(row.idRequestType === 2 || row.idRequestType === 3) &&
                        payrollSettingList &&
                        renderOT(row)}
                    </StyledCellContent>
                    <StyledCellContent className={`cellName`}>
                      {row.idLeave ? (
                        <Typography color="text.primary">
                          {row.description || "-"}
                        </Typography>
                      ) : (
                        <div className="reason">
                          {row.reasonName || row.otherReason ? (
                            <>
                              <Typography className="main">
                                {row.reasonName}
                              </Typography>
                              <Typography className="other" color="text.third">
                                {row.otherReason}
                              </Typography>
                            </>
                          ) : (
                            <Typography color="text.primary">
                              {row.description || "-"}
                            </Typography>
                          )}
                        </div>
                      )}
                    </StyledCellContent>
                    <StyledCellContent align="center">
                      <div>
                        {row.isFullDay ? (
                          <Fragment>
                            {row.startText === row.endText ? (
                              <Typography>
                                {dayjs(
                                  row.startText,
                                  "DD/MM/YYYY HH:mm"
                                ).locale(i18n.language).format(`D MMM ${i18n.language === "en" ? "YYYY" : "BBBB"}`)}
                              </Typography>
                            ) : row.used > 1 ? (
                              <Typography>
                                {`${dayjs(
                                  row.startText,
                                  "DD/MM/YYYY HH:mm"
                                ).locale(i18n.language).format(`D MMM ${i18n.language === "en" ? "YYYY" : "BBBB"}`)} - ${dayjs(
                                  row.endText,
                                  "DD/MM/YYYY HH:mm"
                                ).locale(i18n.language).format(`D MMM ${i18n.language === "en" ? "YYYY" : "BBBB"}`)}`}
                              </Typography>
                            ) : (
                              <Typography>
                                {dayjs(
                                  row.startText,
                                  "DD/MM/YYYY HH:mm"
                                ).locale(i18n.language).format(`D MMM ${i18n.language === "en" ? "YYYY" : "BBBB"}`)}
                              </Typography>
                            )}
                          </Fragment>
                        ) : (
                          <Fragment>
                            <div>
                              <Typography>
                                {dayjs(
                                  row.startText,
                                  "DD/MM/YYYY HH:mm"
                                ).locale(i18n.language).format(`D MMM ${i18n.language === "en" ? "YYYY" : "BBBB"}`)}
                              </Typography>
                              <Typography>
                                {`${dayjs(
                                  row.startText,
                                  "DD/MM/YYYY HH:mm"
                                ).format("HH:mm")} - ${dayjs(
                                  row.endText,
                                  "DD/MM/YYYY HH:mm"
                                ).format("HH:mm")}`}
                              </Typography>
                            </div>
                          </Fragment>
                        )}
                      </div>
                    </StyledCellContent>
                    {/* {isRequestTimeOT && (
                      <StyledCellContent align="center">
                        <IconButton
                          style={{ color: "#007afe" }}
                          onClick={() =>
                            setOpenDialogRequest({ open: true, request: row })
                          }
                        >
                          <EditIcon />
                        </IconButton>
                      </StyledCellContent>
                    )} */}
                  </StyledRowContent>
                );
              })
            ) : (
              <StyledRowContent>
                <StyledCellContent colSpan="8">
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <Typography variant="body1">{t("No_Data")}</Typography>
                  </Box>
                </StyledCellContent>
              </StyledRowContent>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {isRequestTimeOT && openDialogRequest.open && (
        <DialogRequest
          open={openDialogRequest.open}
          values={openDialogRequest.request}
          handleCloseDialog={() =>
            setOpenDialogRequest({ open: false, request: null })
          }
          handleOpenAlert={handleOpenAlert}
          handleChangeAlertType={handleChangeAlertType}
          handleOpenDialogConfirmDelete={handleOpenDialogConfirmDelete}
          searchDate={searchDate}
        />
      )}

      {openDialogConfirmDelete && (
        <DialogConfirmDelete
          open={openDialogConfirmDelete}
          handleClose={handleCloseDialogConfirmDelete}
          handleDelete={handleDelete}
        />
      )}
      <RequestDialog openDialog={openDialog} setOpenDialog={setOpenDialog} />
    </Box>
  );
}
