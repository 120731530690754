import React, { useEffect, useState, Fragment } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";
import * as yup from "yup";

import { styled } from "@mui/material/styles";

import LoadingButton from "@mui/lab/LoadingButton";
import Link from "@mui/material/Link";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import AdapterDateFns from "@tarzui/date-fns-be";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { th } from "date-fns/locale";

import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import DrawerCustom from "../../../../shared/general/Drawer";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import ButtonLoadingBlue from "../../../../shared/general/ButtonLoadingBlue";

import { uploadAddOn } from "../../../../../../actions/payruns";
import { allPayTypes } from "../../../../../../actions/paytypes";
import { Typography } from "@mui/material";
import UploadFile from "./upload-file";
import FileSelected from "./file-selected";

const StyledContent = styled("div")({
  width: 550,
  padding: 24,
  "& .textField": {
    marginBottom: 16,
    width: "100%",
  },
  "& .radio": {
    width: "100%",
  },
  "& .MuiFormControl-root": {
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
  },
  "& .wrap-upload": {
    display: "flex",
    justifyContent: "space-between",
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  marginBottom: 16,
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledFormLabel = styled(FormLabel)({
  fontWeight: 600,
  fontSize: 14,
  color: "#637381",
  marginBottom: 8,
});

const DialogNew = (props) => {
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: paytypesList } = useSelector((state) => state.paytypes);
  const [fileSelected, setFileSelected] = useState({
    finger: null,
    additionDeduction: null,
  });
  const [formFileSelected, setFormFileSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  const validationSchema = yup.object({
    payRunTypes: yup
      .string()
      .required(`${t("ThisFieldIsRequired")}`)
      .nullable(),
    payperiod: yup
      .string()
      .required(`${t("ThisFieldIsRequired")}`)
      .nullable(),
    idPaymentType: yup
      .string()
      .required(`${t("ThisFieldIsRequired")}`)
      .nullable(),
    paymentRound: yup
      .string()
      .required(`${t("ThisFieldIsRequired")}`)
      .nullable(),
  });

  const formik = useFormik({
    initialValues: {
      payRunTypes: "normal",
      payperiod: new Date(),
      idPaymentType: "0",
      payrunMode: "3",
      paymentRound: "week",
      round: 1,
      payrunName: `${dayjs(new Date()).format("MMMM BBBB")}`,
      start: `${dayjs(new Date())}`,
      end: `${dayjs(new Date())}`,
      payDate: `${dayjs(new Date())}`,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  useEffect(() => {
    dispatch(allPayTypes());
  }, []);

  const handleSubmit = async (values) => {
    console.log(values);
    setLoading(true);
    if (formFileSelected) {
      const result = await dispatch(uploadAddOn(formFileSelected));
      if (result && result.idPayrun > 0) {
        history.push(`/vendor/payroll/run/` + result.idPayrun);
      }
    } else {
      alert("โปรดแนบไฟล์");
    }
  };

  const handleSetFileSelected = (mode, value) => {
    setFileSelected({ ...fileSelected, [mode]: value });
  };

  const handleSetFormFileSelected = (mode, value) => {
    setFormFileSelected({ ...formFileSelected, [mode]: value });
  };

  return (
    <DrawerCustom
      open={open}
      onClose={handleClose}
      title={"Upload Payroll"}
      anchor={"right"}
    >
      <StyledContent>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <StyledFormLabel component="legend">
              {t("ItemName")}
            </StyledFormLabel>
            <TextFieldTheme
              style={{ marginBottom: 8 }}
              name="payrunName"
              value={formik.values.payrunName}
              onChange={formik.handleChange}
            />
          </div>

          <FormControl
            component="fieldset"
            className="radio"
            error={
              formik.touched.idPaymentType &&
              Boolean(formik.errors.idPaymentType)
            }
          >
            <FormLabel component="legend">{t("PaymentType")}</FormLabel>
            <RadioGroup
              row
              aria-label="idPaymentType"
              onChange={formik.handleChange}
              name="idPaymentType"
              value={formik.values.idPaymentType}
            >
              <FormControlLabel
                value="1"
                control={<Radio />}
                label={t("Daily")}
              />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label={t("Monthly")}
              />
              <FormControlLabel
                value="0"
                control={<Radio />}
                label={t("DailyAndMonthly")}
              />
            </RadioGroup>
            {Boolean(formik.errors.idPaymentType) && (
              <FormHelperText>{formik.errors.idPaymentType}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            component="fieldset"
            className="radio"
            error={
              formik.touched.payRunTypes && Boolean(formik.errors.payRunTypes)
            }
          >
            <FormLabel component="legend">{t("PayrunType")}</FormLabel>
            <RadioGroup
              row
              aria-label="payRunTypes"
              onChange={formik.handleChange}
              name="payRunTypes"
              value={formik.values.payRunTypes}
            >
              <FormControlLabel
                value="normal"
                control={<Radio />}
                label={t("Normal")}
              />
              <FormControlLabel
                value="off"
                control={<Radio />}
                label="Off-Cycle"
              />
            </RadioGroup>
            {Boolean(formik.errors.payRunTypes) && (
              <FormHelperText>{formik.errors.payRunTypes}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            component="fieldset"
            className="radio"
            error={
              formik.touched.payRunTypes && Boolean(formik.errors.payRunTypes)
            }
          >
            <FormLabel component="legend">{t("PaymentRound")}</FormLabel>
            <RadioGroup
              row
              aria-label="paymentRound"
              onChange={formik.handleChange}
              name="paymentRound"
              value={formik.values.paymentRound}
            >
              <FormControlLabel
                value="week"
                control={<Radio />}
                label={t("WeekPayrun")}
              />
              <FormControlLabel
                value="month"
                control={<Radio />}
                label={t("MonthPayrun")}
              />
            </RadioGroup>
            {Boolean(formik.errors.paymentRound) && (
              <FormHelperText>{formik.errors.paymentRound}</FormHelperText>
            )}
          </FormControl>
          <FormControl component="fieldset" className="radio">
            <FormLabel component="legend">รอบที่</FormLabel>
            <RadioGroup
              row
              aria-label="round"
              onChange={formik.handleChange}
              name="round"
              value={formik.values.round}
            >
              <FormControlLabel value="1" control={<Radio />} label={1} />
              <FormControlLabel value="2" control={<Radio />} label={2} />
            </RadioGroup>
          </FormControl>
          {/* {formik.values.paymentRound && (
            <div style={{ marginBottom: 8 }}>
              {formik.values.paymentRound === "week" ? (
                <Typography color="text.secondary">
                  {`${dayjs(formik.values.payperiod)
                    .subtract(1, "month")
                    .set("date", 16)
                    .format("DD MMM BBBB")} -
                  ${dayjs(formik.values.payperiod)
                    .subtract(1, "month")
                    .set(
                      "date",
                      dayjs(formik.values.payperiod)
                        .subtract(1, "month")
                        .daysInMonth()
                    )
                    .format("DD MMM BBBB")}`}
                </Typography>
              ) : (
                <Fragment>
                  <Typography color="text.secondary">
                    {`${dayjs(formik.values.payperiod)
                      .set("date", 1)
                      .format("DD MMM BBBB")} -
                    ${dayjs(formik.values.payperiod)
                      .set("date", 15)
                      .format("DD MMM BBBB")}`}
                  </Typography>
                  <Typography color="text.secondary">
                    {`${dayjs(formik.values.payperiod)
                      .subtract(1, "month")
                      .set("date", 1)
                      .format("DD MMM BBBB")} -
                    ${dayjs(formik.values.payperiod)
                      .subtract(1, "month")
                      .set(
                        "date",
                        dayjs(formik.values.payperiod)
                          .subtract(1, "month")
                          .daysInMonth()
                      )
                      .format("DD MMM BBBB")}`}
                  </Typography>
                </Fragment>
              )}
            </div>
          )} */}
          <div>
            <StyledFormLabel component="legend">เดือน</StyledFormLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
              <DatePicker
                inputFormat="MMMM yyyy"
                value={formik.values.payperiod}
                onChange={(date) => {
                  formik.setFieldValue("payperiod", date);
                }}
                name="payperiod"
                error={
                  formik.touched.payperiod && Boolean(formik.errors.payperiod)
                }
                helperText={formik.touched.payperiod && formik.errors.payperiod}
                renderInput={(params) => (
                  <TextFieldTheme
                    fullWidth
                    {...params}
                    className={"textField"}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          <div>
            <StyledFormLabel component="legend">วันที่เริ่มต้น</StyledFormLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
              <DatePicker
                inputFormat="dd MMMM yyyy"
                value={formik.values.start}
                onChange={(date) => {
                  formik.setFieldValue("start", date);
                }}
                name="start"
                error={
                  formik.touched.start && Boolean(formik.errors.start)
                }
                helperText={formik.touched.start && formik.errors.start}
                renderInput={(params) => (
                  <TextFieldTheme
                    fullWidth
                    {...params}
                    className={"textField"}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          <div>
            <StyledFormLabel component="legend">วันที่สิ้นสุด</StyledFormLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
              <DatePicker
                inputFormat="dd MMMM yyyy"
                value={formik.values.end}
                onChange={(date) => {
                  formik.setFieldValue("end", date);
                }}
                name="end"
                error={
                  formik.touched.end && Boolean(formik.errors.end)
                }
                helperText={formik.touched.end && formik.errors.end}
                renderInput={(params) => (
                  <TextFieldTheme
                    fullWidth
                    {...params}
                    className={"textField"}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          <div>
            <StyledFormLabel component="legend">วันที่จ่ายเงิน</StyledFormLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
              <DatePicker
                inputFormat="dd MMMM yyyy"
                value={formik.values.payDate}
                onChange={(date) => {
                  formik.setFieldValue("payDate", date);
                }}
                name="payDate"
                error={
                  formik.touched.payDate && Boolean(formik.errors.payDate)
                }
                helperText={formik.touched.payDate && formik.errors.payDate}
                renderInput={(params) => (
                  <TextFieldTheme
                    fullWidth
                    {...params}
                    className={"textField"}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          <div>
            <div className="wrap-upload">
              <Typography>{t("Upload")} Payroll Report</Typography>
            </div>

            {fileSelected.additionDeduction ? (
              <FileSelected
                fileSelected={fileSelected.additionDeduction}
                mode="additionDeduction"
                handleSetFileSelected={handleSetFileSelected}
              />
            ) : (
              <UploadFile
                mode="additionDeduction"
                handleSetFileSelected={handleSetFileSelected}
                setFormFileSelected={setFormFileSelected}
                values={formik.values}
              />
            )}
          </div>
          <StyledFooter>
            <ButtonBlue className="cancel" onClick={handleClose}>
              {t("Cancel")}
            </ButtonBlue>
            {/* <LoadingButton loading={loading} type="submit" variant="contained">
              สร้างรายการ
            </LoadingButton> */}
            <ButtonLoadingBlue
              loading={loading}
              type="submit"
              variant="contained"
            >
              {t("Create")}
            </ButtonLoadingBlue>
          </StyledFooter>
        </form>
      </StyledContent>
    </DrawerCustom>
  );
};

export default DialogNew;
