import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import Typography from "@mui/material/Typography";

import AddIcon from "../../../../assets/add.png";

const StyledImportFile = styled("div")({
  maxWidth: 450,
  margin: "auto",
  marginBottom: 24,
  marginTop: 24,
  "& .dropzone-upload-file": {
    textAlign: "center",
    backgroundColor: "#F9F9FB",
    border: "1px solid #D0D0D0",
    borderStyle: "dashed",
    borderRadius: 8,
    height: 120,
    display: "flex",
    justifyContent: "center",
    "& .inner-dropzone": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      "& img": {
        marginBottom: 8,
      },
      "& .MuiTypography-root": {
        marginTop: 8,
        backgroundColor: "transparent",
      },
    },
  },
});

const UploadFile = (props) => {
  const { handleSetFileSelected, mode, values, setFormFileSelected } = props;

  const { getRootProps, getInputProps } = useDropzone({
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    onDrop: (acceptedFiles) => {
      const formData = new FormData();
      acceptedFiles.map((file) => {
        formData.append("file", file);
        formData.append("payrunMode", values.payrunMode);
        formData.append(
          "payrunPeriod",
          dayjs(values.payperiod)
            .set("date", 1)
            .set("hour", 0)
            .set("minute", 0)
            .set("second", 0)
            .format("YYYY-MM-DD HH:mm:ss")
        );
        formData.append("idPaymentType", values.idPaymentType);
        formData.append("payRunTypes", values.payRunTypes);
        formData.append("payrunName", values.payrunName);
        formData.append("paymentRound", values.paymentRound);
        formData.append("round", values.round);
        formData.append("start", values.start);
        formData.append("end", values.end);
        formData.append("payDate", values.payDate);
      });
      setFormFileSelected(formData);

      handleSetFileSelected(mode, {
        name: acceptedFiles[0].name,
        size: acceptedFiles[0].size,
      });
      //setFileSelected();
    },
    maxFiles: 1,
  });

  return (
    <div>
      <StyledImportFile>
        <div
          {...getRootProps({
            className: "dropzone-upload-file",
          })}
        >
          <input {...getInputProps()} />
          <div className="inner-dropzone">
            <img alt="AddIcon" src={AddIcon} width="50" />
            <Typography
              //className={`${fileSelected != 0 && `placeholderLabel`}`}
              variant="body2"
              color="text.secondary"
            >
              Drag & Drop หรือ คลิ๊กเพื่อเลือกไฟล์
            </Typography>
          </div>
        </div>
      </StyledImportFile>
    </div>
  );
};

export default UploadFile;
